.artical-baner {
    background-image: url(../../../assets/images/articles-bg.png);
}
.artical-baner::after {
    background: transparent linear-gradient(90deg, #D8DDDD 0%, #D8DDDD00 100%) 0% 0% no-repeat padding-box;
}
.artical-box-wraper {
    background-size: cover;
    border-radius: 10px;
    min-height: 350px;
    position: relative;
    background-position: center center;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
}
.artical-box-wraper::after {
    background: transparent linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(85, 85, 85, 0) 16%, rgba(0, 0, 0, 0.0980392157) 57%, rgba(0, 0, 0, 0.7764705882) 100%) 0% 0% no-repeat padding-box;
}
.artical-info {
    z-index: 1;
}
.artical-box-wraper:hover .hover--layout {
    height: 100%;
    visibility: visible;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.hover--layout {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #000000BA;
    border-radius: 10px;
    visibility: hidden;
}
/* artical detail page */
.artical-detail-baner {
    background-color: #D8DDDD;
    padding: 0 !important;
    min-height: 400px;
    display: flex;
    align-items: end;
    background-position-y: center;
}
.artical-detail-content {
    padding: 30px 0;
}
.image-container {
    width: 100%;
    height: 350px;
    margin: 0 auto;
}
.image-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.border-right{
    border-right: 2px solid white;
}
.artical-detail-heading{
    color: #F5F5F5;
} .f-size-20{
    font-size: 20px;
}
@media only screen and (max-width: 1600px) {
    .artical-box-wraper {
        min-height: 300px;
    }
    .artical-baner {
        padding: 100px 0;
    }
    .artical-detail-baner {
        min-height: 300px;
    }
    .f-size-20{
        font-size: 16px;
    }
}
@media only screen and (max-width: 1399px) {
    .artical-box-wraper {
        min-height: 250px;
    }
   
}

@media (max-width:575px){
    .artical-box-wraper {
        min-height: 205px;
    }
    .artical-baner {
        padding: 78px 0;
    }
    .artical-detail-baner {
        min-height: 200px;
    }
    .f-size-20{
        font-size: 13px;
    }
}
@media (max-width:480px){
    .artical-baner {
        padding: 28px 0;
    }
}