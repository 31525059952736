.cms-pages-baner.consultation-baner{
    background-image: url(../../../assets/images/consultbg.png);
    background-repeat: no-repeat;
    background-size: 100%;
    padding:125px 0;
    position: relative;
  }
  .cms-pages-baner.consultation-baner::after {
    background: transparent linear-gradient(90deg, #D8DDDD 0%, #D8DDDD00 100%) 0% 0% no-repeat padding-box;
  }
  .event-baner .cms-content-heading {
    max-width: 545px;
    text-align: right;
    z-index: 2;
    position: relative;
  }
  .consult-text{
    text-align: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  .clock_box{
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    max-width: 292px;
    padding: 20px;
    border: 8px solid #D5AD67;
  }
  .offset-right{
    bottom: 190px;
    right: 144px;
  }
  .offset-left {
    left: 9%;
    top: 28%;
}
.date_picker .rmdp-container  {
  width: 100%;
}
.date_picker input{
  width: 100%;
  height: 60px;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 26px;
  font-weight: 300;
  background-image: url(../../../assets/images/CalenderIcon.svg);
  background-repeat: no-repeat;
  background-position-x: 20px;
  background-position-y: calc(100% - 13px);
}
.consult-max-wd{
  max-width: 444px;
}
.consult__form{

  max-height: 862px;
}

@media(max-width:1367px){
  .cms-pages-baner.consultation-baner {
    background-position-x: center;
    background-size: cover;
}
.social_screen img.m-auto{
  width: 800px;
}
.clock_box {
  max-width: 245px;
  padding: 8px;
  border: 3px solid #D5AD67;
}
}



@media (max-width: 992px){
  .offset-left {
    left: 11%;
    top: -9%;
}
.offset-right {
  bottom: 7%;
  right: 3%;
}
}


@media(max-width:767px){
.cms-pages-baner.consultation-baner {
  padding: 80px 0;
}
.event-baner .cms-content-heading {
  text-align: center;
}
.social_screen p{
  font-size: 12px;
}
.clock_box {
  max-width: 181px;
  padding: 8px;
}
}

@media (max-width: 768px){
  .offset-left {
    left: 4%;
    top: -17%;
}
.circle-block {
  max-width: 25px;
  height: 25px;
  width: 100%;
}
.listing_f-sze {
  font-size: 12px;
  line-height: 21px;
}
}

@media (max-width:480px){
  .cms-pages-baner.consultation-baner {
    padding: 39px 0;
  }
  .f-size-70 {
    font-size: 27px;
}
.consultation_wrapper .f-size-17{
  font-size: 12px;
}
.social_screen p {
  font-size: 10px;
}
.clock_box {
  max-width: 156px;
  padding: 8px;
}
.offset-left {
  left: -1%;
  top: -28%;
}
.offset-right {
  bottom: -17%;
  right: 3%;
}
.consultation_wrapper .f-size-70 {
  font-size: 23px;
}
.consultation_wrapper .f-size-17 {
  font-size: 10px;
}
}

