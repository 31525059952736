.login_In{
  background-image: url(../../../assets/images/Union.png);
	background-repeat: repeat;
    height: 100%;
	background-color:#D8DDDD19;
  min-height: 100vh;
}
.heading-content{
  max-width: 50%;
  padding: 0px 65px;
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}
.staric{
  color: #B40000;
  font-size: 26px;
  font-weight: bold;
}
.form-box input{
  font-size: 16px;
  border: 1px solid #D8DDDD;
  border-radius: 10px;
  opacity: 1;
  outline:none;
  padding: 18px 16px;
  letter-spacing: 0px;
  width: 100%;
  font-weight: 300;
}
.form-box input::placeholder{
  color: #373737;
  opacity: 1;
  font-weight: 300;
}
.eye-button{
  position: absolute;
  width: 20px;
  height: 13px;
  left: 12px;
  top: 50%;
  transform: translatey(-50%);
}
.color-red{
  color: #B40000;
}
.auth-left_banner{
  background-image: url(../../../assets/images/authOne.png);
  height: 100%;
  max-width: 50%;
  flex: 0 0 50%;
  min-height: 850px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.center_slider-image img{
  position: absolute;
  min-height: 1040px;
  left: 50%;
  top: -30px;
  transform: translateX(-50%);
 
}
.auth-left_banner h2{
  color: #373737;
  text-shadow: 0px 3px 6px #00000000;
  opacity: 1;
  font-weight: bold;
  font-size: 58px;
  text-align: center;
}
.login-text-left{
  border-radius: 10px;
  background-image:url(../../../assets/images/loginlayer.svg); 
  max-width: 550px;
  padding: 53px 28px;
}
.forget_wraper .auth-left_banner{
  background-image: url(../../../assets/images/forgetbg.png);
}
.forget_wraper .login-text-left{
  background-image:url(../../../assets/images/forgetLayer.svg); 

}
.sign_up_wraper .auth-left_banner{
  background-image: url(../../../assets/images/auth_girl.png);
    min-height: 913px;
}
.grey-color{
  color: #707070;
}
.forget_wraper .heading-content{
  justify-content: unset;
}
.forget__btn .theme-btn{
  max-width: 254px;
}
.left-arow svg{
  height: 20px;
  width: 20px;
}
.ribbon__wraper{
  margin-right: -82px;
}
.ribbon__wraper .ribbon{
  padding: 30px 90px 30px 40px;

}
.hyper-sign{
  width: 19px;
  height: 19px;
  background: #D5AD67 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.login_In::after{
  content: "";
  background-image: url(../../../assets/images/VerticalSlide.png);
  width: 50%;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.sign_up_wraper .auth-left_banner.verfify_left {
  min-height: 414px;
}


@media (max-width:1740px){
  .auth-left_banner h2 {
    font-size: 50px;
}
.login-text-left {
  max-width: 427px;
  padding: 38px 19px;
}
.login-text-left {
  max-width: 527px;
  padding: 40px 19px;
}
.auth-left_banner {
  min-height: 750px;
}
.sign_up_wraper .ribbon__wraper{
  margin-top: 3rem
}
.sign_up_wraper .auth-left_banner {
  min-height: 914px;
}
}

@media (max-width:1540px){
  .user__icon img{
    width: 75px;
  }
  .auth-left_banner h2 {
    font-size: 50px;
}
  .login_In::after{
    width: 80%;

  }
  .login_In .ribbon__wraper {
    margin-top: 1rem;
}
  .sign_up_wraper .ribbon__wraper {
    margin-top: 1rem;
}
  .form-box .f-size-17{
    font-size: 15px;
  }
  .auth-left_banner {
    min-height: 606px;
}
  .login-text-left {
    max-width: 427px;
    padding: 16px 19px;
}
  .auth-left_banner h2 {
    font-size: 41px;
}
.form-box input {
  font-size: 14px;
  padding: 13px 16px;
}
.theme-btn {

  font-size: 14px;
}
}
@media (max-width:1367px){
  .auth-left_banner {
 
    min-height: 601px;
  }
  .login-text-left {
    max-width: 365px;
    padding: 27px 19px;
}


  .center_slider-image img {
    top: -123px;
  }
  .ribbon__wraper .ribbon {
    padding: 20px 40px 20px 40px;
  }

.heading-content {

    max-width: 50%;
    padding: 0px 27px;
}
.ribbon__wraper {
  margin-right: -38px;
}
.eye-button {
  top: 24px;
}
.forget__btn .theme-btn {
  max-width: 193px;
}
.auth-left_banner h2 {
  font-size: 34px;
}
.login_In::after {
  width: 80%;
}
}

@media (max-width:992px){
  .login-text-left {
    max-width: 377px;
  }   
  .auth-left_banner h2 {

    font-size: 32px;
  }
  .heading-content {
    padding: 0px 14px;
  }
  .ribbon__wraper {
    margin-right: -24px;
  }
}

@media (max-width:768px){
  .auth-left_banner{
    display: none;
  }
  .heading-content {
    max-width: 100%;
    padding: 35px 40px;
    flex: 0 0 100%;
  }
 
  .login_In::after{
   
    display: none;

  }
}


@media (max-width:480px){
  .heading-content {
    padding: 25px 20px;
  }
 .login_In .theme-btn {
    font-size: 13px;
  }
  .ribbon__wraper {
    margin-right: -30px;
  }
  .f-size-17 {
    font-size: 14px;
  }
  .form-box .f-size-17 {
    font-size: 14px;
  }
  .mbl_flex_col{
    flex-direction: column;
  }
  .login_In .theme-btn {
  max-width: 100%;
  font-size: 14px;
  margin-bottom: 10px;
  width: 100%;
  }
  .form-box input {
    font-size: 12px;
    padding: 10px 16px;
}
.forget__btn{
  flex-direction: column;
}
.forget__btn .theme-btn{
  margin-bottom: 20px;
}
.user__icon img {
  width: 56px;
}
}
