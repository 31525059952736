.about-baner {
    background-image: url(../../../assets/images/about-bg.png);
}
.cms-pages-baner.about-baner::after {
    background: transparent linear-gradient(90deg, #D8DDDD 0%, #D8DDDD00 100%) 0% 0% no-repeat padding-box;
}
.benefit__wrapper::after{
    content: "";
    background-image: url(../../../assets/images/benefitbg.png);
    height: 100%;
    width: 100%;
    position: absolute;
    top: -79px;
    min-height: 1133px;
}
.about_num{
    font-size: 94px;
    color: #D5AD67;
    font-family: 'Lato';
}
.benefits_number{
    max-width: 1060px;
    margin: 0 auto;
    justify-content: space-between;
}
.benefit_heading h3{
    font-size: 73px;
    color: #000000;
    text-shadow: 0px 3px 6px #00000000;
    opacity: 1;
    font-weight: 500;
}
.benefit_heading p{
    max-width: 1214px;
    margin: 0 auto;
}
.benefits_card{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 4px #00000029;
    border-radius: 7px;
    opacity: 1;
    max-width: 376px;
    text-align: center;
    padding: 39px;
    min-height: 270px;
    height: 100%;
}

@media(max-width:1580px){
    .about_num {
        font-size: 76px;
      }
      .benefit_heading h3 {
        font-size: 64px;
      }
      .benefits_number {
        max-width: 967px;
      }
}

@media(max-width:1367px){
    .about_num {
        font-size: 64px;
      }
      .benefit_heading h3 {
        font-size: 55px;
      }
      .benefits_card {
        padding: 15px;
      }
      .benefits_number {
        max-width: 829px;
      }
      .benefit_heading p {
        max-width: 935px;
        margin: 0 auto;
      }
}

@media(max-width:991px){
    .about_num {
        font-size: 53px;
      }
      .benefits_number {
        max-width: 660px;
      }
      .benefits_card img{
        width: 70px;
      } 
      .benefit_heading h3 {
        font-size: 46px;
      }
      .about__card{
        flex-direction: column;
      }
      .benefits_card{
        margin-bottom: 20px
      }
}

@media(max-width:991px){
    .benefits_number {
        max-width: 522px;
      }
}
@media(max-width:767px){
.about_num {
    font-size: 40px;
  }
}

@media(max-width:575px){
.flex-cmd{
    flex-direction: column;
}
.number_content .dark_color{
    font-size: 14px;
}
.benefit_heading h3 {
    font-size: 36px;
  }
  .about_num {
    font-size: 30px;
  }
  .benefits_card{
    margin-left: 0;
  }
  .benefits_number {
    max-width: 337px;
  }
  .cms-pages-baner.about-baner{
    padding: 39px 0px;
  }
}