 /* dashboard-content inner section */

 .light-tan{
    background: #E3DDD5;
    border: 1px solid #E7E7E7;
 }
 .bg-gray{
    background: #D8DDDD;
    border: 1px solid #E7E7E7;
 }
 .bg-lightmauve{
    background: #DED4D6;
    border: 1px solid #E7E7E7;
 }
 .lato{
    font-family: 'Lato';
 }
 .common-white-bgcard{
    background: #FFFFFF;
    border-radius: 4px;
    padding: 20px;
 }
 .dashboard-wraper {
    padding: 47px 360px 40px 40px;
    background: #F5F5F5;
    min-height: 100vh;
}
.dashboard-states-wraper {
    width:100%;
    display: flex;
}
.states-days{
    width: calc(100% - 30%);
}
.dashboard-states{
    background: #FFFFFF;
    border-radius: 15px 15px 4px 4px;
    -webkit-border-radius: 15px 15px 4px 4px;
    -moz-border-radius: 15px 15px 4px 4px;
    -ms-border-radius: 15px 15px 4px 4px;
    -o-border-radius: 15px 15px 4px 4px;
}

.states-header{
    background-color: #D5AD67;
    background-image: url(../../assets/images/bashbaord/dashfigure-bg.png);
    background-size: 100% 100%;
    border-radius: 15px 15px 4px 4px;
    background-repeat: no-repeat;
    display: flex;
    align-items: end;
    min-height:120px;
}
.dashboard-states-content {
    padding: 50px;
}
.profile-picture {
    margin-top: -160px;
    width: 100%;
    height: auto;
}
.profile-picture img{
    border-radius: 50%;
    object-fit: cover;
    height: 147px;
    width: 147px;
    border: 2px solid #fff;
}
.stats-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.stats-info h5{
    font-family: 'Lato';
    font-size: 55px;
    font-weight: 500;
    line-height: 72px;
}
.cl-dates p{
    font-size: 20px;
    line-height: 30px;
    font-weight: bold;
}
.qoute-name {
    border-top: 1px solid #F5F5F5;
    padding: 20px;
}
.cl-dates{
    padding: 20px;
}
.qoute-name{
    font-size: 22px;
    line-height: 44px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #373737;
    text-shadow: 0px 3px 6px #00000000;
}

.consultation-time {
    background: #F5F5F5;
    box-shadow: 0px 0px 18px #C6C6C666;
    border: 1px solid #E7E7E7;
    border-radius: 4px;
    width: 30%;
    padding: 15px;
    margin-right: 40px;
}
.consult-header {
    background: #D5AD67 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 18px #C6C6C666;
    border: 1px solid #E7E7E7;
    border-radius: 4px;
    padding: 18px 30px 10px 10px;
}
.buton-holder .theme-btn {
    background-color: #fff;
    color: #D5AD67;
    max-width: 150px;
}
ul.event--list li {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 18px #C6C6C666;
    border: 1px solid #E7E7E7;
    border-radius: 4px;
    padding: 18px 30px 10px 10px;
    position: relative;
    z-index: 1;
    margin-top: 15px;
}
.event--list-info h2{
    font-size: 22px;
    line-height: 44px;
    font-weight: bold;
}
.event--list-info p{
    font-size: 14px;
    line-height: 26px;
    color: #373737;
    font-weight: 300;
    text-shadow: 0px 3px 6px #00000000;
}
.calander-row{
    display: flex;
    align-items: center;
}
.calander-row p{
    font-size: 17px;
    color: #373737;
}
ul.event--list li::before{
    content: "";
    background: #E3DDD5 0% 0% no-repeat padding-box;
    border-radius: 0px 4px 4px 0px;
    opacity: 1;
    height: 100%;
    width: 13px;
    position: absolute;
    right: 0;
    top: 0;
}
ul.event--list li:nth-child(3n+1)::before {
    background: #E3DDD5 ;
  }
  
  ul.event--list li:nth-child(3n+2)::before {
    background: #DED4D6;
  }
  
  ul.event--list li:nth-child(3n+3)::before {
    background: #D8DDDD;
  }
.f-size-25{
    font-size: 25px;
}
.states-days.common-listing {
    width: 100%;
}
@media only screen and (max-width: 1600px) {
    .dashboard-wraper {
        padding: 47px 343px 40px 40px;
    }
    .stats-info h5 {
        font-size: 50px;
        line-height: 60px;
    }
    .cl-dates p {
        font-size: 15px;
        line-height: 20px;
    }
    .f-size-25 {
        font-size: 20px;
    }
    .qoute-name h4{
        font-size: 25px;
    }
    .qoute-name h4 {
        font-size: 18px;
        text-align: center;
    }
    .qoute-name,.cl-dates{
        padding: 10px;
    }
    .consultation-time {
        width: 33%;
        padding: 15px;
        margin-right: 25px;
    }
    .states-days {
        width: 65%;
    }
    .states-days.common-listing {
        width: 100%;
    }
    .buton-holder .theme-btn {
        max-width: 130px;
        height: 48px;
    }
    .dashboard-states-content {
        padding: 30px;
    }
}
@media only screen and (max-width: 1399px) {
    
    .dashboard-states-content {
        padding: 20px;
    }
    .stats-info h5 {
        font-size: 40px;
        line-height: 42px;
    }
    .profile-picture  {
        margin-top: -95px;
       
    }
    .profile-picture img {
        height: 147px;
        width: 147px;
    }
 
    .f-size-25 {
        font-size: 12px;
    }
    .qoute-name h4 {
        font-size: 14px;
    }
    .event--list-info p {
        font-size: 12px;
        line-height: 18px;
    }
    span.image-wraper {
        width: 15px;
        height: 15px;
    }
    .states-header{
        min-height:80px;
    }
}
@media only screen and (max-width: 1199px) {
    .dashboard-wraper {
        padding: 65px 40px 40px 40px;
      }
    .stats-info h5 {
        font-size: 28px;
        line-height: 35px;
    }
    .dashboard-states-content {
        padding: 15px;
    }
    .profile-picture {
        margin-top: -92px;
    }
    .cl-dates p {
        font-size: 13px;
        line-height: 20px;
    }
    .qoute-name h4 {
        font-size: 13px;
    }
    .event--list-info .f-size-22 {
        font-size: 16px;
    }
  
}
@media only screen and (max-width: 992px) {
    .states-days {
        width: 100%;
    }
    .consultation-time {
      width: 100%;
      padding: 15px;
      margin-right: 0;
      margin-top: 15px;
    }
    .dashboard-states-wraper {
      width: 100%;
      display: flex;
      flex-direction: column;
  }
}
@media only screen and (max-width: 575px) {
.states-header{
    background-size: cover;
}
.dashboard-wraper {
    padding: 65px 15px 15px 15px;
}
.profile-picture img {
    height: 96px;
    width: 96px;
}
}